<template>
    <div>
        <Navigation />
        <!-- 版权侵权告知书 -->
        <div class="bg_box">
            <div class="noneBox"></div>
            <div class="box">
                <p class="back" @click="$router.push('/aiNav')">返回</p>
                <div class="ct_box flex">
                    <div class="form_upload_box">
                        <img src="@/assets/ai/paint20.png" alt="" class="title">
                        <div class="form_ct flex">
                            <p class="upLoad_text">原告：</p>
                            <div class="upload_box">
                                <el-input v-model="form.defendant_name" placeholder="请输入侵权公司名称或个人姓名"></el-input>

                            </div>
                        </div>
                        <div class="form_ct flex">
                            <p class="upLoad_text">被告：</p>
                            <div class="upload_box">
                                <el-input v-model="form.plaintiff_name" placeholder="请输入告知人姓名"></el-input>
                            </div>
                        </div>
                        <div class="form_ct flex">
                            <p class="upLoad_text">案由：</p>
                            <div class="upload_box">
                                <el-input v-model="form.plaintiff_case" placeholder="请输入案由"></el-input>
                            </div>
                        </div>
                        <div class="form_ct flex">
                            <p class="upLoad_text">诉讼请求：</p>
                            <div class="upload_box">
                                <el-input :autosize="{ minRows: 4, maxRows: 4 }" class="upload_textarea" type="textarea"
                                    v-model="form.plaintiff_appeal" placeholder="具体的案情事实和起诉理由"></el-input>
                            </div>
                        </div>

                        <div class="form_ct flex">
                            <p class="upLoad_text">事实和理由：</p>
                            <div class="upload_box">
                                <el-input :autosize="{ minRows: 4, maxRows: 4 }" class="upload_textarea" type="textarea"
                                    v-model="form.plaintiff_fact" placeholder="具体的案情事实和起诉理由"></el-input>
                            </div>
                        </div>
                        <ai_button_file_data ref="ai_button_file_data" @file_data="file_data" />
                        <ai_text_content :contentData="contentData" />
                        <aiNum title="计费规则：1000tokens = 0.9元，" text="1000tokens在700-750字左右" :visible="aiNumShow"
                            @close="aiNumShow = false" @postData="postData" />
                        <aiPoup :visible="aiPoupShow" @close="aiPoupShow = false" />
                    </div>
                    <aiNav />

                </div>

            </div>


        </div>
        <footerBot />
    </div>
</template>

<script>
import ai_text_content from '@/components/ai_text_content'
import aiNav from '@/components/aiNav'
import ai_button_file_data from '@/components/ai_button_file_data'
export default {
    name: '',
    created() { },
    mounted() {
        this.getUserInfo()
    },
    components: {
        aiNav,
        ai_text_content,
        ai_button_file_data
    },
    data() {
        return {
            form: {
                defendant_name: '', //原告
                plaintiff_name: '', //被告
                plaintiff_case: '', //案由
                plaintiff_appeal: '', //诉讼要求
                plaintiff_fact: '', // 事实理由
            },
            contentData: {

            }, //内容
            aiNumShow: false,
            aiPoupShow: false,
        }
    },
    methods: {
        getImg(url) {
            this.contentData.origin_url = url
            console.log('contentData', this.contentData);
        },
        // 获取用户信息
        getUserInfo() {
            let $user_info = JSON.parse(localStorage.getItem('user_info'))
            if ($user_info) {
                this.curlGet('/api/user/info').then((res) => {
                    if (res.data.code) {
                        this.$user_info = res.data.data
                        this.$util.setLocalStorage('user_info', res.data.data) // 写入local storage
                    }
                })
            }
        },
        file_data() {
            if (this.$user_info.money <= 0) {
                this.aiPoupShow = true
            } else {
                this.aiNumShow = true
            }
        },
        postData() {
            this.aiNumShow = false
            this.$refs.ai_button_file_data.loading_show()
            let data = this.form
            data.mobile = this.$user_info.mobile
            this.curlPost('file/indictment_create', data).then(res => {
                this.$refs.ai_button_file_data.loading_show()
                if (res.data.code) {
                    this.contentData = res.data.data
                    // 扣费
                    this.curlPost('/api/users/money_log/spend', {
                        ai_id: res.data.data.id,
                        amount: res.data.data.money,
                    }).then(res => {
                        if (res.data.code) {
                            this.getUserInfo()
                        }
                    })
                } else {
                    this.$message.error(res.data.msg);
                }
            })
        }

    },
}
</script>

<style lang=scss scoped>
@import '@/scss/ai'
</style>